import React, { useState, useEffect } from 'react';
import './App.css';
import SignIn from './Components/SignIn';
import Dashboard from './Components/dashboard';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = localStorage.getItem('isLoggedIn');
    if (loggedIn === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const checkLogin = (username, password) => {
    if (username === 'admin' && password === 'vlh@123') {
      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');
      return true;
    }
    return false;
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <Dashboard onLogout={handleLogout} />
      ) : (
        <SignIn checkLogin={checkLogin} />
      )}
    </div>
  );
}

export default App;